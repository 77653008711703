import Api from '@/services/Api'

export default {
  createSmsRequest(params) {
    return Api().post('/api/smsTestRequests', params)
  },
  getRequests(params) {
    return Api(undefined, { hideAlert: true }).get('/api/smsTestRequests', { params })
  },
  updateSmsRequests(params) {
    return Api().put('/api/smsTestRequests', { params })
  },
}
